import { Grid, Image } from '@aws-amplify/ui-react'
import { useMediaQuery } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function LoaderPage() {
   
    const media = useMediaQuery('(max-width:1266px)')
    const navigate = useNavigate()
    const nav = () => {
        navigate('/Home')
    }

    React.useEffect(() => {
        const go = () => {
            // console.log("navigate")
        setTimeout(nav, 5000)
        }
        go()
    }, [])
    

    

    return(
   <Grid
      position="relative"
    >

    {media ? <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/phoneloadernow.gif"
    // srcSet='/home.svg 300w'
    alt="Home Logo"
    width="100%"
    height="100%"
    objectFit="cover"
    // objectPosition="top"
    position="fixed"
    // overflow="scroll"
    /> :
    <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/forhood.gif"
    // srcSet='/home.svg 300w'
    alt="Home Logo"
    width="100%"
    height="100%"
    objectFit="cover"
    // objectPosition="top"
    position="fixed"
    // overflow="scroll"
    /> 
    }
    

  </Grid>)
}