import { Button, Text } from '@aws-amplify/ui-react'
import { Grid, TextField } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import addskater from '../appuitls/addskater'
import NavBar from './NavBar'


export default function FirstSessionSignUp() {
  

    
    const [first, setfirst] = React.useState("")
    const [last, setlast] = React.useState("")
    const [dob, setdob] = React.useState("")
    const [school, setschool] = React.useState("")
    const [yearsSkating, setyearsSkating] = React.useState("")
    const [shoeSize, setshoeSize] = React.useState("")
    const [brandToSkate, setbrandToSkate] = React.useState("")
    const [boardSize, setboardSize] = React.useState(0)
    const [parentName, setparentName] = React.useState("")
    const [parentEmail, setparentEmail] = React.useState("")
    const [parentPhone, setparentPhone] = React.useState("")
    const [address, setaddress] = React.useState("")
    const [state, setstate] = React.useState("")
    const [city, setcity] = React.useState("")
    const [zipcode, setzipcode] = React.useState("")
    

    const [isdisabled, setisdisabled] = React.useState(true)


    React.useEffect(() => {
      if(parentEmail === "" || 
        first === "" ||
        last === "" ||
        dob === "" ||
        school === "" ||
        yearsSkating === "" ||
        shoeSize === "" ||
        brandToSkate === "" ||
        boardSize === 0 ||
        parentName === 0 ||
        parentPhone === "" ||
        address === "" ||
        state === "" ||
        city === "" ||
        zipcode === ""
        ){
          // alert("All fields required!")
          setisdisabled(true)
          return
        }
      setisdisabled(false)
      
    }, [parentEmail ,first,last,dob,school,yearsSkating,shoeSize,brandToSkate, boardSize, parentName,parentPhone, address, state, city, zipcode])

    const navigate = useNavigate()
    const SignUp = async () => {

      await addskater(parentEmail ,first,last,dob,school,yearsSkating,shoeSize,brandToSkate, boardSize, parentName,parentPhone, address, state, city, zipcode);
      console.log("Successfully Signed Up!")
      alert("You've successfully Signed Up Skater!")
      navigate('/Home')
    }

    
    
  return (
    <Grid container
    display="block"
    // justifyContent="center"
    // alignItems="center"
    position="relative"
    height="100%"
    width="100%"
    // backgroundColor="red"
    // overflow="unset"
    // overflow="hidden"
    // direction="column"
    >
        <NavBar/>

{/* <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/ehrbggifsignup.gif"
    // srcSet='/home.svg 300w'
    alt="Home Logo"
    width="100%"
    height="100vh"
    objectFit="cover"
    // objectPosition="top"
    position="fixed"
    // overflow="scroll"
    /> */}

    <Grid container
    display="flex"
    justifyContent="center"
    alignItems="center"
    position="relative"
    height="100%"
    // padding="50px 40px 50px 40px"
    // borderRadius="15px"
    // backgroundColor="rgba(255,255,255,.8)" 
    width="100%"
    >



      <Grid container item
            // maxWidth="650px"
            width="100%"
            // backgroundColor="pink"
        // height=""
        // maxHeight="200vh"
        // display={(props.showsignup ? "flex" : "none")}
            flexWrap="nowrap"
        // overflow="scroll"
        // gap="unset"
        // alignItems="center"
        // justifyItems="center"
            justifyContent="center"
        // paddingLeft="20px"
        // paddingRight="20px"
            paddingTop="45px"
            paddingBottom="50px"
            position="relative"
            borderRadius="15px"
            boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
            // borderRadius="20px"
            backgroundColor="rgba(255,255,255, .6)"
            // backgroundColor="rgba(32, 78, 41, 0.8)"
        >






    
        <Grid item container
    // maxWidth="524px"
    // minWidth="400px"
        height="auto"
        display="flex"
        gap="unset"
        direction="column"
        alignItems="unset"
        justifyContent="unset"
        position="relative"
    // top="10px"
    // marginLeft="20px"
    // marginRight="20px"
    // paddingLeft="100px"
    // paddingLeft="20px"
    // paddingRight="20px"
    // left="48px"
    // paddingTop="20px"
        paddingLeft="50px"
        paddingRight="50px"
    // padding="0px 0px 0px 0px"
    // backgroundColor="grey"
   
    >
   

    {/* Sign Up Text  */}
      <Grid container
    position="relative"
    // minWidth="100px"
    height="auto"
    display="flex"
    justifyContent="center"
    textAlign="center"
    wrap="nowrap"
    // wrap="nowrap"
    // paddingBottom="20px"
    // padding
    // backgroundColor="brown"
      >

    
      <Text
        fontFamily="Nunito"
        fontSize="40px"
        // fontWeight="700"
        color="green"
        lineHeight="50px"
        textAlign="centers"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-0.85px"
        
        // backgroundColor="pink"
        // width="169px"
        // height="47px"
        gap="unset"
        alignItems="unset"
        position="relative"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
      >

        Skater Sign Up

      </Text>
   

      </Grid>
    {/* Sign Up Text  */}


 {/* Enter Username, Email, Sign Up Screen */}
      <Grid container
        as="form"
        gap="20px"
        direction="column"
        width="unset"
        // height="100%"
        display="flex"
        // justifyContent="flex-start"
        // alignItems="flex-start"
        position="relative"
        // top="117px"
        left="0px"
        // padding="0px 0px 0px 0px"
        paddingTop="15px"
        // backgroundColor="yellow"
        >

{/* Enter Username, Email, Password, Forgot password. Reset New Group */}
     
<Grid container
    position="relative"
    // minWidth="100px"
    height="auto"
    display="flex"
    justifyContent="flex-start"
    // textAlign="center"
    wrap="nowrap"
// wrap="nowrap"

// paddingBottom="20px"
// padding
// backgroundColor="brown"
>

    
      <Text
        fontFamily="Nunito"
        fontSize="30px"
        // fontWeight="700"
        color="green"
        lineHeight="50px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-0.85px"
        
        // backgroundColor="pink"
        // width="169px"
        // height="47px"
        gap="unset"
        alignItems="unset"
        position="relative"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        
       
      >
        Skater Information
      </Text>
   

      </Grid>

      <TextField
          label="First Name ..."
          value={first}
    onChange={(e) => {
      let { value } = e.target;
      setfirst(value);
    }}
 
           
          >  
      </TextField>

      <TextField
    
            // // className="Sign-Up"
            label="Last Name ..."
            value={last}
            onChange={(e) => {
            let { value } = e.target;
            setlast(value);
             }}
             >
          
            
            
         
      </TextField>

      <TextField
            label="DOB ..."
            value={dob}
            onChange={(e) => {
            let { value } = e.target;
            setdob(value);
             }}
             >
          
                {/* <Text
                  fontFamily="Nunito"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(107,107,107,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Enter Email ..."
                  {...getOverrideProps(overrides, "Placeholder2144")}
                ></Text> */}
            
         
      </TextField>

      <TextField
    
    // // className="Sign-Up"
    label="School ..."
    value={school}
    onChange={(e) => {
    let { value } = e.target;
    setschool(value);
     }}
     />
     <TextField
    
    // // className="Sign-Up"
    label="Shoe Size ..."
    value={shoeSize}
    onChange={(e) => {
    let { value } = e.target;
    setshoeSize(value);
     }}
     />

<TextField
    
    // // className="Sign-Up"
    label="Years Skating ..."
    value={yearsSkating}
    onChange={(e) => {
    let { value } = e.target;
    setyearsSkating(value);
     }}
     />
   
    

<TextField
    
    // // className="Sign-Up"
    label="Board Size ..."
    value={boardSize}
    onChange={(e) => {
    let { value } = e.target;
    setboardSize(value);
     }}
     />

<TextField
    
    // // className="Sign-Up"
    label="Favorite Brand To Skate ..."
    value={brandToSkate}
    onChange={(e) => {
    let { value } = e.target;
    setbrandToSkate(value);
     }}
     />
  

      <Grid container
    position="relative"
    // minWidth="100px"
    height="auto"
    display="flex"
    justifyContent="flex-start"
    // textAlign="center"
    wrap="nowrap"
    // wrap="nowrap"
    // paddingBottom="20px"
    // padding
    // backgroundColor="brown"
      >

    
      <Text
        fontFamily="Nunito"
        fontSize="30px"
        // fontWeight="700"
        color="green"
        lineHeight="50px"
        textAlign="centers"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-0.85px"
        
        // backgroundColor="pink"
        // width="169px"
        // height="47px"
        gap="unset"
        alignItems="unset"
        position="relative"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
      >

        Parent Information

      </Text>
   

      </Grid>
      <TextField
          label="Parent Name ..."
          value={parentName}
    onChange={(e) => {
      let { value } = e.target;
      setparentName(value);
    }}
 
           
          />  

      <TextField
          label="Parent Email ..."
          value={parentEmail}
    onChange={(e) => {
      let { value } = e.target;
      setparentEmail(value);
    }}
 
           
          /> 

          <TextField
          label="Parent Phone ..."
          value={parentPhone}
    onChange={(e) => {
      let { value } = e.target;
      setparentPhone(value);
    }}
 
           
          />  
         


      <Text
        fontFamily="Nunito"
        fontSize="30px"
        // fontWeight="700"
        color="green"
        lineHeight="50px"
        textAlign="centers"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-0.85px"
        
        // backgroundColor="pink"
        // width="169px"
        // height="47px"
        gap="unset"
        alignItems="unset"
        position="relative"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        
       
      >
        Address 
      </Text>

      <TextField
             label="Street Name ..."
             value={address}
             multiline
            onChange={(e) => {
            let { value } = e.target;
            setaddress(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="State ..."
             value={state}
         
            onChange={(e) => {
            let { value } = e.target;
            setstate(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="City ..."
             value={city}
          
            onChange={(e) => {
            let { value } = e.target;
            setcity(value);
             }}
             >
      </TextField>

      <TextField
             label="Zip Code ..."
             value={zipcode}
            onChange={(e) => {
            let { value } = e.target;
            setzipcode(value);
             }}
             >
            
         
      </TextField>

    

{/* Sign Up Button */}
      <Button
        variation="primary"
        width="unset"
        height="48px"
        display="flex"
        
        gap="unset"
        alignItems="center"
        justifyContent="center"
        paddingLeft="10px"
        paddingRight="10px"
        // wrap="wrap"
       
        // top="12px"
        position="relative"
        borderRadius="15px"
        // padding="10px 10px 10px 10px"
        isDisabled={isdisabled}
        onClick={() => {
          SignUp()
        }}
        backgroundColor="green"
        // opacity="0.5"
       
      >

        
        <Text
            fontFamily="Nunito"
            fontSize="19px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            // children="Sign up"
          
          >
            Sign Up
          </Text>

        
      </Button>
{/* Sign Up Button */}

        </Grid>
{/* Enter Username, Email, Sign Up Screen */}

        </Grid>


      
      </Grid>

    </Grid>

    </Grid>
  )
}
