import { Text, View } from '@aws-amplify/ui-react'

import React from 'react'

export default function OrganizationCard(props) {
    const data = props.data



  return (
   <View
   display="flex"
   direction="column"
   backgroundColor="rgba(32, 78, 41, 1)"
   width="auto"
   height="100%"
   paddingLeft="20px"
   paddingRight="20px"
   paddingTop="20px"
   paddingBottom="20px"

   
   >



    <Text
    color="white"
    textAlign="center"
    paddingBottom="20px"
    >
      {data.organizationName}
    </Text>
    <Text
    color="white">
        Organization EIN : {data.einNumber}
    </Text>

    <Text
    color="white">
        Contact Person : {data.contactPerson}
    </Text>
    <Text
    color="white">
        Contact Person Title : {data.contactTitle}
    </Text>
    <Text
    color="white">
        Contact Person Phone Number : {data.phone}
    </Text>
    <Text
    color="white">
        Contact Person Email : {data.email}
    </Text>

    <Text
    color="white">
        Address : {data.street_name}
    </Text>
    <Text
    color="white">
        State : {data.state}
    </Text>
    <Text
    color="white">
        City : {data.city}
    </Text>
    <Text
    color="white">
        Zip Code : {data.zip_code}
    </Text>

    <Text
    color="white">
        Estimated Pounds Picking Up : {data.estimatedPounds}
    </Text>


   </View>

   
  )
}
