import { Flex, Text } from '@aws-amplify/ui-react'
import { Grid, TextField } from '@mui/material'
import React from 'react'
import NavBar from './NavBar'
import addorganization from '../appuitls/addorganization'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'



export default function StandAloneOrgPage() {
  

 
    const [email, setemail] = React.useState("")
    const [organizationName, setorganizationName] = React.useState("")
    const [contactPerson, setcontactPerson] = React.useState("")
    const [einNumber, seteinNumber] = React.useState("")
    const [positionTitle, setpositionTitle] = React.useState("")
    const [estimatedPounds, setestimatedPounds] = React.useState("")
    const [streetName, setstreetName] = React.useState("")
    const [state, setstate] = React.useState("")
    const [city, setcity] = React.useState("")
    const [zipcode, setzipcode] = React.useState("")
    const [phone, setphone] = React.useState("")
   
    const navigate = useNavigate()
    

    const submitOrg = () => {
      if(organizationName === "" || 
        // password === "" ||
        email === "" ||
        contactPerson === "" ||
        positionTitle === "" ||
        estimatedPounds === "" ||
        streetName === "" ||
        phone === "" ||
        state === "" ||
        city === "" || 
        zipcode === ""
        )
        {
          alert("All fields must be field out!")
          
        } else {
            
            addorganization(email, organizationName, contactPerson, positionTitle, einNumber, phone, estimatedPounds, streetName, state, city, zipcode);
            alert("Successfully Signed Up Organization!")
            navigate('/')
        }
        
    }

    
    
  return (

    <Grid
    container
    position="relative"
    width="auto"
    height="auto"
    // backgroundColor="red"
    >
    <NavBar />

    <Grid container
    display="block"
    position="relative"
    
    height="100%"
    width="100%"
    
    
    // backgroundColor="red"
    // overflow="unset"
    // overflow="hidden"
    // direction="column"
    >

{/* <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/ehrbggifsignup.gif"
    // srcSet='/home.svg 300w'
    alt="Home Logo"
    width="100%"
    height="100vh"
    objectFit="cover"
    // objectPosition="top"
    position="fixed"
    // overflow="scroll"
    /> */}

    <Grid container
    display="flex"
    justifyContent="center"
    alignItems="center"
    position="relative"
    height="100%"
    // padding="50px 40px 50px 40px"
    // borderRadius="15px"
    // backgroundColor="rgba(255,255,255,.8)" 
    width="100%"
    >

{/* <Flex
      width="100%"
      position="relative"
      
      // backgroundColor="red"
      paddingRight="15px"
      paddingTop="10px"
      justifyContent="end"
      onClick={() => {
        navigate('/Home')
      }}
      >
X
      </Flex> */}



      <Grid container item
            // maxWidth="650px"
            width="100%"
            // backgroundColor="pink"
        // height=""
        // maxHeight="200vh"
        // display={(props.showsignup ? "flex" : "none")}
            flexWrap="nowrap"
        // overflow="scroll"
        // gap="unset"
        // alignItems="center"
        // justifyItems="center"
            justifyContent="center"
        // paddingLeft="20px"
        // paddingRight="20px"
            paddingTop="45px"
            paddingBottom="50px"
            position="relative"
            borderRadius="15px"
            boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
            // borderRadius="20px"
            backgroundColor="rgba(255,255,255, .9)"
        >






    
        <Grid item container
  
        height="auto"
        display="flex"
        gap="unset"
        direction="column"
        alignItems="unset"
        justifyContent="unset"
        position="relative"
  
        paddingLeft="50px"
        paddingRight="50px"
   
   
    >
   

    {/* Sign Up Text  */}
      <Grid container
    position="relative"
 
    height="auto"
    display="flex"
    justifyContent="center"
    textAlign="center"
    wrap="nowrap"

>

    
      <Text
        fontFamily="Nunito"
        fontSize="40px"
        // fontWeight="700"
        color="green"
        lineHeight="50px"
        textAlign="centers"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-0.85px"
      
        gap="unset"
        alignItems="unset"
        position="relative"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        
       
      >
        Organization Form
      </Text>
   

      </Grid>
    {/* Sign Up Text  */}


 {/* Enter Username, Email, Sign Up Screen */}
      <Grid container
        as="form"
        gap="20px"
        direction="column"
        width="unset"
        // height="100%"
        display="flex"
        // justifyContent="flex-start"
        // alignItems="flex-start"
        position="relative"
        // top="117px"
        left="0px"
        // padding="0px 0px 0px 0px"
        paddingTop="15px"
        // backgroundColor="yellow"
        >

{/* Enter Username, Email, Password, Forgot password. Reset New Group */}
     

      <TextField
          label="Organization Name ..."
          value={organizationName}
    onChange={(e) => {
      let { value } = e.target;
      setorganizationName(value);
    }}
 
           
          >  
      </TextField>

      <TextField
            label="Contact Person ..."
            value={contactPerson}
            onChange={(e) => {
            let { value } = e.target;
            setcontactPerson(value);
             }}
             >
          
                {/* <Text
                  fontFamily="Nunito"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(107,107,107,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Enter Email ..."
                  {...getOverrideProps(overrides, "Placeholder2144")}
                ></Text> */}
            
         
      </TextField>

      <TextField
             label="Position or Title In Organization ..."
             value={positionTitle}
          
            onChange={(e) => {
            let { value } = e.target;
            setpositionTitle(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="Estimated Pounds being picked up ..."
             value={estimatedPounds}
          
            onChange={(e) => {
            let { value } = e.target;
            setestimatedPounds(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="EIN Number ..."
             value={einNumber}
          
            onChange={(e) => {
            let { value } = e.target;
            seteinNumber(value);
             }}
             >
            
         
      </TextField>


      <TextField
    
            // // className="Sign-Up"
            label="Email ..."
            value={email}
            onChange={(e) => {
            let { value } = e.target;
            setemail(value);
             }}
             >
          
            
            
         
      </TextField>


      <TextField
             label="Street Name ..."
             value={streetName}
             multiline
            onChange={(e) => {
            let { value } = e.target;
            setstreetName(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="State ..."
             value={state}
         
            onChange={(e) => {
            let { value } = e.target;
            setstate(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="City ..."
             value={city}
          
            onChange={(e) => {
            let { value } = e.target;
            setcity(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="Zip Code ..."
             value={zipcode}
             

            onChange={(e) => {
            let { value } = e.target;
            setzipcode(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="Phone Number ..."
             value={phone}
            onChange={(e) => {
            let { value } = e.target;
            setphone(value);
             }}
             >
          
             
            
         
      </TextField>

{/* Sign Up Button */}

<motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        
      
        >
      <Flex
     
        variation="primary"
        width="unset"
        height="48px"
        display="flex"
        gap="unset"
        alignItems="center"
        justifyContent="center"
        paddingLeft="10px"
        paddingRight="10px"
        // wrap="wrap"
       
        // top="12px"
        position="relative"
        borderRadius="15px"
        // padding="10px 10px 10px 10px"
     
      
        backgroundColor="green"
        onClick={() => {
  
          submitOrg()
      
        }}
       
       
      >


        <Text
            fontFamily="Nunito"
            fontSize="19px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
          
            // children="Sign up"
          >
            Submit Organization Form
          </Text>
         

        
      </Flex>
      </motion.button>
    
{/* Sign Up Button */}

        </Grid>
{/* Enter Username, Email, Sign Up Screen */}

        </Grid>


      
      </Grid>
    </Grid>

    </Grid>

    </Grid>
  )
}
