

export default async function addorganization(email, organizationName, contactPerson, positionTitle, einNumber, phone, estimatedPounds, streetName, state, city, zipcode) {

    // require('dotenv').config()
   
    const Realm = require("realm-web"); 
    const REALM_ID = process.env.REACT_APP_PUBLIC_REALM_APP_ID;
    const REALM_KEY = process.env.REACT_APP_PUBLIC_REALM_APP_KEY;
    // console.log("Id", REALM_ID)
    // console.log("key", REALM_KEY)
    const app = new Realm.App({id: REALM_ID});
    const {
        BSON: { ObjectId },
    } = Realm;

  // Authenticate the user
    try {
        const credentials = Realm.Credentials.apiKey(REALM_KEY)
        await app.logIn(credentials);
        // console.log(user)
    } catch (error) {
        console.log("Error in Logging", error) 
    }

    

  
    const mongo = app.currentUser.mongoClient("ehrcluster");
    const collection = mongo.db("ehrusers").collection("organizations");
    
    try {

    const results = await collection.insertOne({

        "email": email,
        "organizationName": organizationName,
        "contactPerson": contactPerson,
        "contactTitle": positionTitle,
        "einNumber": einNumber,
        "estimatedPounds": estimatedPounds,
        "street_name": streetName,
        "state": state,
        "city": city,
        "zip_code": zipcode,
        "phone": phone,
        
    })

    
    return results

    } catch (error) {

    console.log("Error from pulling Colletcion", error)  

    } 


  
}