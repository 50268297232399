import { Button, Flex, Image, Text, TextField, View } from '@aws-amplify/ui-react'
import { Grid, useMediaQuery } from '@mui/material';

import React from 'react'

import findvolunteer from '../appuitls/findvolunteer';
import Modal from 'react-modal'
import VolunteerPage from './VolunteerPage';
import NavBar from './NavBar';

Modal.setAppElement('#root'); 
export default function HomePage() {
const matches = useMediaQuery('(min-width:800px)');
  const [email, setemail] = React.useState("")

    const [isdisabled, setisdisabled] = React.useState(true);
    const [UserConfirmed, setUserConfirmed] = React.useState(false)
    const closeUserConfirmed = () => {
      setUserConfirmed(false)
      window.location.href = 'https://ehr90z.wixsite.com/my-site-2'
    }
    const [ConfirmSignUp, setConfirmSignUp] = React.useState(false)
    const closeConfirmSignUp = () => {
      setConfirmSignUp(false)
    }
  

    const SignUp = async () => {
      
      const volunteer = await findvolunteer(email)
      // console.log("Volunteer", volunteer)

      if(volunteer === null){
        // console.log("No User Continue")
        setConfirmSignUp(true)
        // navigate("/Volunteer")
      } else {
        setUserConfirmed(true)
        
        // console.log("User Exist!") 
        return
      }
      // setVolunteerSignUp(true)
    }

    React.useEffect(() => {
      Modal.setAppElement('body');
      
  }, []);


  return (
    <Grid container
    display="flex"
    // justifyContent="center"
    // alignItems="center"
    position="relative"
    height={matches ? "100vh" : "100%"}
    width="100%"
    // backgroundColor="red"
    // overflow="unset"
    // overflow="scroll"
    // direction="column"
    >
      <div 
      style={{
        backgroundImage:"radial-gradient(130% 100% at 0% 0%, #737373,#0C8645)"
      }}
      className='absolute h-[100vh] w-full overflow-hidden'
      
      />

      <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/Ehrbg.gif"
    // srcSet='/home.svg 300w'
    alt="Home Logo"
    width="100%"
    height="100%"
    objectFit="cover"
    // objectPosition="top"
    position="fixed"
    // overflow="scroll"
    />
    
    
    <View
    position="relative"
    height="75px"
    width="100%">
    <NavBar/>
    </View>
    

      <Grid container item
    position="relative"
    display="flex"
    direction="column"
    // justifyContent="center"
    justifyContent="flex-start"
    paddingTop="25px"
    // paddingBottom="15px"
   
    height="auto"
    // backgroundColor="pink"
    wrap='nowrap'
    



    >

    
     {/* <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/8.png"
    // srcSet='/home.svg 300w'
    // backgroundColor="red"
    alt="Home Logo"
    width="100%"
    height="700px"
    objectFit="cover"
    // style={{
    //   zIndex:99
    // }}
    // objectPosition="top"
    position="relative"
    // overflow="scroll"
    /> */}

    <View
        position="relative"
        paddingLeft="50px"
        paddingRight="50px"
        // paddingTop="30px"
        width="100%"
        display="flex"
        justifyContent="center"
        // backgroundColor="pink"
        >
          

        <Grid container
        maxWidth="650px"
        minWidth="100px"
        //  height=""
        //  maxHeight="200vh"
        // display={(props.showsignup ? "flex" : "none")}
        flexWrap="nowrap"
        direction="column"
        display="flex"    
        justifyContent="center"
        alignItems="center"
        paddingTop="20px"
        paddingBottom="35px"
        position="relative"
        boxShadow="0px 5px 15px rgba(0, 0, 0, 0.2)"
        // boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        borderRadius="16px"
        // backgroundColor="rgba(255,255,255, .9)"
        backgroundColor="rgba(32, 78, 41, 0.8)"
        >

<Text
fontSize="20px"
fontWeight="bold"
color="white"
paddingBottom="10px"
>
  Volunteer Sign Up
</Text>

<Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/ehr.png"
    // srcSet='/home.svg 300w'
    // backgroundColor="red"
    alt="Home Logo"
    width="500px"
    height="220px"
    objectFit="cover"
    // display="flex"
    // justifyContent="center"
    // paddingLeft="20px"
    // style={{
    //   zIndex:99
    // }}
    // objectPosition="top"
    position="relative"
    // overflow="scroll"
    />


   
    
      <Grid item container
    // maxWidth="524px"
    // minWidth="400px"
      height="auto"
      display="flex"
      gap="unset"
      direction="column"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
    // top="10px"
    // marginLeft="20px"
    // marginRight="20px"
    // paddingLeft="100px"
    // paddingLeft="20px"
    // paddingRight="20px"
    // left="48px"
    // paddingTop="20px"
      paddingLeft="50px"
      paddingRight="50px"
    // padding="0px 0px 0px 0px"
    // backgroundColor="grey"
   
    >
   

    {/* Sign Up Text  */}
    <Grid container
    position="relative"
    // minWidth="100px"
    height="auto"
    display="flex"
    justifyContent="center"
    textAlign="center"
    wrap="nowrap"
// wrap="nowrap"

// paddingBottom="20px"
// padding
// backgroundColor="brown"
>

   

    </Grid>
    {/* Sign Up Text  */}


 {/* Enter Username, Email, Sign Up Screen */}
    <Grid container
      as="form"
      gap="10px"
      direction="column"
      width="unset"
      // height="100%"
      display="flex"
      // justifyContent="flex-start"
      // alignItems="flex-start"
      position="relative"
      // top="117px"
      left="0px"
      // padding="0px 0px 0px 0px"
      paddingTop="5px"
      // backgroundColor="yellow"
 
    >

{/* Enter Username, Email, Password, Forgot password. Reset New Group */}
     
     

{/* Enter Username TextField              */}


      {/* <TextField
            // width="unset"
            width="unset"
            // maxWidth="524px"
            height="48px"
            display="block"
            // color='white'
            
            // variation="quiet"
            // size="default"
            // gap="unset"
            // alignItems="unset"
            // justifyContent="unset"
            // shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // border="1.5px SOLID rgba(131,168,184,1)"
            // borderRadius="8px"   
            // borderRadius="8px"   
            // backgroundColor="rgba(255,255,255,1)"
            placeholder="Enter Username ..."
            className="Sign-Up"

            value={username}
    onChange={(e) => {
      let { value } = e.target;
      setusername(value);
    }}
    // onBlur={() => runValidationTasks("username", username)}
    // errorMessage={errors.username?.errorMessage}
    // hasError={errors.username?.hasError}
           
          >  
      </TextField> */}

<Text
color="white"
height="auto"

// backgroundColor="red"
>
  Enter Email ...
</Text>
{/* Enter Email TextField */}
        <TextField
           
            
           
            
            
          
            name="username" 
            autoComplete="username"
            className="Volunteer"
            value={email}
            onChange={(e) => {
            let { value } = e.target;
         
            if(value === ""){
         
              setemail(value);
              setisdisabled(true)
              return
            } 
            setemail(value);
            setisdisabled(false)
             }}
             >
          
                {/* <Text
                  fontFamily="Nunito"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(107,107,107,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Enter Email ..."
                  {...getOverrideProps(overrides, "Placeholder2144")}
                ></Text> */}
            
         
      </TextField>
      
  
{/* Enter Password TextField */}

{/* Select Field */}

        {/* <TextField
          id="outlined-select-currency"
          select
          label="Sign Up As?"
          defaultValue="Volunteer"
          // helperText="Please select your currency"
        >
          
            <MenuItem key="Volunteer" value="Volunteer">
              {
                    `Volunteer`
              }
            </MenuItem>
          
        </TextField>
         */}
{/* Select Field */}

{/* Sign Up Button */}
{/* <Link to={"/Volunteer"} email={email}> */}

      <Button
        variation="primary"
        width="unset"
        height="48px"
        display="flex"
        gap="unset"
        alignItems="center"
        justifyContent="center"
        paddingLeft="10px"
        paddingRight="10px"
        isDisabled={isdisabled }
        
        // className=" disabled:bg-black"
        // className="cursor-pointer"
        // color="black"
        // wrap="wrap"

        // top="12px"
        position="relative"
        borderRadius="15px"
        // padding="10px 10px 10px 10px"
        // isDisabled={isdisabled}
        onClick={() => {
          SignUp()
        }}
        backgroundColor="green"
        // opacity="0.5"
       
      >

        
          <Text
            fontFamily="Nunito"
            fontSize="19px"
            fontWeight="600"
           
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            color="white"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            // children="Sign up"
          
          >
            Continue
          </Text>

        
      </Button>
    
      {/* </Link> */}
{/* Sign Up Button */}
    </Grid>
{/* Enter Username, Email, Sign Up Screen */}

        </Grid>


      
        </Grid>

    </View>

    <Flex
    
      position="relative"
      
      
      height="100%"
      
   
      
      width="100%"
    
  
      
      // backgroundColor="green"
      // paddingBottom="20px"
      paddingTop="50px"
      
      alignItems="flex-end"
      
     
    
      >

     
        
      <p 
      // className='relative flex w-[100%] h-auto text-white justify-center items-end pl-5 pr-5 pt-10 text-[14px] '
      className='relative h-auto text-white text-[14px] bg'
      >
        <Text
        backgroundColor="rgba(32, 78, 41, 0.8)"
        paddingRight="15px"
        paddingLeft="15px"
        paddingBottom="15px"
        paddingTop="10px"
        color="white">
       
        If you're seeking to obtain our most recent financial report that has been formally submitted, we encourage you to get in touch with our team. Please direct your request to our official email address at info@environmentalhoodrestoration.org / environmentalhoodrestoration@gmail.com, and we will assist you by providing you with the requested document. Your interest in our financial affairs is greatly appreciated, and we're here to ensure you have all the information you need.
        </Text>
      </p>
      


     

      </Flex>


  {/* <Link to="/Volunteer">
      <Button

      variation='primary'
      position="relative"
      width="100px"
      height="40px"
      top="0px"
  // bottom="100px"
      borderRadius="5px"
      backgroundColor="rgba(0,128,0,.8)"
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
      >
        
        <Text
          position="relative"
          fontSize="15px"
          fontWeight="hairline"
          >
            Sign Up
        </Text>
        
      </Button>
      </Link> */}


      </Grid>

      

      <Modal
      isOpen={ConfirmSignUp}
      onRequestClose={closeConfirmSignUp}
      style={{
        overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'flex-start',
        // alignItems: 'center',
        overflow: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 999,
        // paddingTop:"20px",
        // paddingBottom:"20px",
        padding:"30px 30px 30px 30px"
      },

        content: {
        position: 'relative',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '0px solid #ccc',
      //   background: 'blue',
      //   width: "302px",
        // overflow: 'auto',
        width:"100%",
        backgroundColor:"rgba(255,255,255,.8)",
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        height:"auto",
        // maxWidth:"600px",
        // paddingTop:"20px",
        // paddingBottom:"20px",
        boxShadow:"0px 5px 15px rgba(0, 0, 0, 0.2)",
        outline: 'none',
        padding:"0px"
        }
      }}
      >

      <VolunteerPage
      email={email}
      />

      </Modal>

      <Modal
      isOpen={UserConfirmed}
      onRequestClose={closeUserConfirmed}
      style={{
        overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 999,
        // paddingTop:"20px",
        // paddingBottom:"20px",
        padding:"20px 30px 20px 30px"
      },

        content: {
        position: 'relative',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '0px solid #ccc',
      //   background: 'blue',
      //   width: "302px",
        // overflow: 'auto',
        width:"100%",
        backgroundColor:"rgba(255,255,255,.8)",
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        height:"auto",
        maxWidth:"600px",
        // paddingTop:"20px",
        // paddingBottom:"20px",
        boxShadow:"0px 5px 15px rgba(0, 0, 0, 0.2)",
        outline: 'none',
        padding:"0px"
        }
      }}
      >

<Grid container
        maxWidth="650px"
        minWidth="100px"
        //  height=""
        //  maxHeight="200vh"
        // display={(props.showsignup ? "flex" : "none")}
        flexWrap="nowrap"
        direction="column"
        display="flex"
        // overflow="scroll"
        // gap="unset"
        // alignItems="center"
        // justifyItems="center"
        justifyContent="center"
        alignItems="center"
        // paddingLeft="20px"
        // paddingRight="20px"
        paddingTop="20px"
        paddingBottom="45px"
        position="relative"
        boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
        borderRadius="16px"
        backgroundColor="rgba(255,255,255, .6)"
        >



<Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/ehr.png"
    // srcSet='/home.svg 300w'
    // backgroundColor="red"
    alt="Home Logo"
    width="600px"
    height="260px"
    objectFit="cover"
    // display="flex"
    // justifyContent="center"
    // paddingLeft="20px"
    // style={{
    //   zIndex:99
    // }}
    // objectPosition="top"
    position="relative"
    // overflow="scroll"
    />


    
      <Grid item container
    // maxWidth="524px"
    // minWidth="400px"
      height="auto"
      display="flex"
      gap="unset"
      direction="column"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
    // top="10px"
    // marginLeft="20px"
    // marginRight="20px"
    // paddingLeft="100px"
    // paddingLeft="20px"
    // paddingRight="20px"
    // left="48px"
    // paddingTop="20px"
      paddingLeft="50px"
      paddingRight="50px"
    // padding="0px 0px 0px 0px"
    // backgroundColor="grey"
   
    >
   

    {/* Sign Up Text  */}
    <Grid container
    position="relative"
    // minWidth="100px"
    height="auto"
    display="flex"
    justifyContent="center"
    textAlign="center"
    wrap="nowrap"
// wrap="nowrap"

// paddingBottom="20px"
// padding
// backgroundColor="brown"
>

   

    </Grid>
    {/* Sign Up Text  */}


 {/* Enter Username, Email, Sign Up Screen */}
    <Grid container
      as="form"
      gap="20px"
      direction="column"
      width="unset"
      // height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      // top="117px"
      left="0px"
      // padding="0px 0px 0px 0px"
      paddingTop="10px"
    //   backgroundColor="yellow"
 
    >

      <Text
      fontSize="18px"
      textAlign="center"
      // width="30px"
      >
        You've already Signed Up as a Volunteer!
      </Text>
    </Grid>
{/* Enter Username, Email, Sign Up Screen */}

        </Grid>


      
        </Grid>

      </Modal>



  </Grid>
  )
}