import './App.css';
import HomePage from './custom-components/HomePage';
import { Route, Routes } from "react-router-dom";
import VolunteerPage from './custom-components/VolunteerPage';
import LoaderPage from './custom-components/LoaderPage';
import Starter from './custom-components/Starter';
import StandAloneOrgPage from './custom-components/StandaloneOrgPage';
import OrganizationView from './custom-components/OrganizationView';
import FirstSessionSignUp from './custom-components/FirstSkateSession';

function App() {
  return (
    <Routes>
    
      <Route path='VolunteerSignUp' element={<HomePage />}/>
      <Route path="Home" element={<Starter />}/>
      <Route path='*' element={<LoaderPage />}/>
      <Route path='Volunteer' element={<VolunteerPage />}/>
      <Route path='OrganizationView' element={<OrganizationView />}/>
      <Route path='Organization' element={<StandAloneOrgPage />}/>
      <Route path='/' element={<LoaderPage />}/>
      <Route path='SkaterSignUp' element={<FirstSessionSignUp />}/>
      
      </Routes>
  );
}

export default App;
