import { Collection, Image } from '@aws-amplify/ui-react'
import { Grid } from '@mui/material'
import React from 'react'
import NavBar from './NavBar'

import getorganizations from '../appuitls/getorganizations'
import OrganizationCard from './OrganizationCard'
import { useNavigate } from 'react-router-dom'

export default function OrganizationView() {

    const navigate = useNavigate()
    const initialValue = []
    const [orgData, setorgData] = React.useState(initialValue)
   

    const GetOrgs = async () => {
        try {
            const data = await getorganizations()
            setorgData(data)
            
        } catch (error) {
       
        }
       
    }
    React.useEffect(() => {
      GetOrgs()
    }, [])

    const endSession = () => {
        localStorage.setItem('showOrgView', 'false')
    }
    React.useEffect(() => {
    const storedData = localStorage.getItem('showOrgView');
    if(storedData === null || storedData === 'false'){
        navigate('/')
    }
    setTimeout(() => {endSession()}, 50000)

    }, [])
    


    
  return (
    <Grid container
    display="block"
    position="relative"
    height="70vh"
    width="100%"
 
    
    overflow="no-scroll"

    >

    <Image
    src="/phoneloadernow.gif"
    alt="Home Logo"
    width="100%"
    height="100vh"
    objectFit="cover"
    position="fixed"
    />

    <NavBar />

    <Grid container
    position="relative"
    display="flex"
    justifyContent="center"
    width="100%"
    height="auto"
    padding="40px 20px 20px 20px"
    



    
    >
     <Collection
        width="100%"
        height="100%"
        items={orgData || []}
        isSearchable
        isPaginated
        itemsPerPage={1}
        >
            {(item, index) => (
                
            <OrganizationCard 
            key={index}
            data={item}
            />
            )}

        </Collection> 
        
       

    </Grid>
    </Grid>

  )
}
