import { Collection, Flex, Image, Text, View } from '@aws-amplify/ui-react';
import { Grid, useMediaQuery } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import React, { useRef } from 'react'
import img7 from "./flyers/xmas.jpeg"
import img6 from "./flyers/openhearth2.png"
import {motion } from 'framer-motion'

export default function CountDown() {

    const matches = useMediaQuery('(min-width:800px)');

    const handleDonationClicked = () => {
  window.open("https://donate.stripe.com/14kdSG6OF7Crcb65kk")

  }
    const imageList = [
  {page : img7},
//   {page : img6},
  
]

  

    const [timerDays, settimerDays] = React.useState('00');
    const [timerHours, settimerHours] = React.useState('00');
    const [timerMinutes, settimerMinutes] = React.useState('00');
    const [timerSeconds, settimerSeconds] = React.useState('00');

    let interval = useRef()

    const startTimer = () => {
        const countdownDate = new Date('December 24, 2024 00:00:00').getTime();

        interval = setInterval(() =>{
            const now = new Date().getTime();
            const distance = countdownDate - now;
            const days = Math.floor(distance / (1000 * 60 *  60 * 24));
            const hours = Math.floor(distance % (1000 * 60 *  60 * 24) /(1000 * 60 * 60));
            const minutes = Math.floor(distance % (1000 * 60 *  60 ) / (1000 * 60));
            const seconds = Math.floor(distance % (1000 * 60 ) / 1000);

            if(distance < 0){
                clearInterval(interval.current);
                // Stop Timer
            } else {
                settimerDays(days);
                settimerHours(hours);
                settimerMinutes(minutes);
                settimerSeconds(seconds);
            }
        }, 1000)}

        React.useEffect(() => {
            startTimer()
          }, [])

          const [organizationForm, setorganizationForm] = React.useState(false)
          const closeOrgForm = ()=> {
            setorganizationForm(false)
          }
          

  return (

    <Grid container
    position="relative"
    display="flex"
    justifyContent="center"
    direction="column"
    alignItems="center"
    wrap="nowrap"
    width="100%"
    height="auto"
    padding="30px 10px 30px 10px"
    // paddingTop="10px"
    borderRadius="5px"
    boxShadow="0px 5px 15px rgba(0, 0, 0, 0.2)"
    // backgroundColor="pink"
    // backgroundColor="rgba(255,255,255,.9)"
    backgroundColor="rgba(32, 78, 41, 0.8)"
    >
        <View className={`relative flex ${matches ? "flex-row gap-5 " : "flex-col"} justify-center items-center w-full`}>
        <Grid
        container item
        width="auto"
        height="auto"
        // backgroundColor="red"
        display="flex"
        justifyContent="center"
        direction="column"
        alignItems="center"
        color="white"
        >
        <EventNoteIcon
        sx={{
            fontSize:"35px"
        }}
        />

        <Text
        fontWeight="bold"
        fontSize="25px"
        paddingTop="10px"
        textAlign="center"
        paddingBottom="15px"
        color="white"
        >
            TOY DRIVE
        </Text>
        <Flex
        position="relative"
        width="100%"
        justifyContent="center"
        
        border="solid 1px white"
        paddingTop="1px"
        paddingBottom="5px"
        paddingLeft="5px"
        paddingRight="5px"
        
        >
            <Flex
            position="relative"
            width="auto"
            gap="0px"
            direction="column"
            alignItems="center"
         
            >
                <Text
                fontSize="35px"
                color="white"
                >
                    {timerDays}
                </Text>
                <Text
                fontSize='15px'
                color="white"
                >
                    Days
                </Text>
            </Flex>
            <Text
            fontSize="35px"
            color="white"
            >
                :
            </Text>
            <Flex
            position="relative"
            width="auto"
            gap="0px"
            direction="column"
            alignItems="center">
                <Text
                fontSize="35px"
                color="white"
                >
                    {timerHours}
                </Text>
                <Text
                fontSize="15px"
                color="white"
                >
                    Hours
                </Text>
            </Flex>
            <Text
            fontSize="35px"
            color="white"
            >
                :
            </Text>
            <Flex
            position="relative"
            width="auto"
            gap="0px"
            direction="column"
            alignItems="center">
                <Text
                fontSize="35px"
                color="white"
                >
                    {timerMinutes}
                </Text>
                <Text
                fontSize="15px"
                color="white"
                >
                    Minutes
                </Text>
            </Flex>
            <Text
            fontSize="35px"
            color="white"
            >
                :
            </Text>
            <Flex
            position="relative"
            width="auto"
            gap="0px"
            direction="column"
            alignItems="center">
                <Text
                fontSize="35px"
                color="white"
                >
                    {timerSeconds}
                </Text>
                <Text
                fontSize="15px"
                color="white"
                >
                    Seconds
                </Text>
            </Flex>

        </Flex>

        <Flex
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop="30px"
        width="auto"
        height="auto"
        // backgroundColor="red"
      
        >
             {/* <motion.button
       

        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => {
            setorganizationForm(true)
        }}>
            <Text
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="200px"
            height="40px"
            backgroundColor="green"
            borderRadius="5px"
            textAlign="center"
            fontWeight="bold"
            color="white"
            >
            Organization Sign Up
            </Text>
            </motion.button> */}

        </Flex>

        

        </Grid>

        <div className='relative flex flex-col  justify-center items-center space-y-5'>

         <Collection
    items={imageList}
    itemsPerPage={1}
    // type='list'
    // width="300px"
    height="auto"
    display="flex"
    direction="row"
    // isPaginated
    
//    backgroundColor="red"
    
    justifyContent="center"
    alignItems="center"
    >
      
       {(item, index) => (
        
        <Image
        onClick={() => {
          const upcomingEvent = document.getElementById('upcoming-events')
          upcomingEvent.scrollIntoView({behavior: "smooth"})
          // setshowFlyer(true)
        }}
        key={index}
        src={item.page} 
        height="200px"
        objectFit="fill"
        borderRadius="15px"
        
        />
        
       )}
      
      
    </Collection>
    {/* // For Button if Needed  */}
    <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleDonationClicked}
        // style={{
        //     paddingTop: matches ? 0 : 20
        // }}
        

      
        >
        
      
  <Text 
     display="flex"
           justifyContent="center"
           alignItems="center"
           width="160px"
           height="60px"
           backgroundColor="#387756"
          
           
           borderRadius="5px"
           textAlign="center"
           fontWeight="bold"
           color="white"
           className=' hover:bg-#0C8645 '
    
    
  //  marginBottom="100px"
  
    
    onClick={() =>{
          window.open("https://a.co/8t77kbO")
          
        }}

  >Go To Amazon Wishlist</Text> 
  </motion.button>
  </div>

        </View>

        {/* <Modal
      isOpen={organizationForm}
      onRequestClose={closeOrgForm}
      style={{
        overlay: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-start',
            height:"105%",
        overflow: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 999,
        paddingTop:"68px"
    
        // padding:"68px 30px 30px 0px"
      },

        content: {
        position: 'relative',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '0px solid #fffff',
    
        width:"100%",
        backgroundColor:"rgba(255,255,255,1)",
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        height:"auto",
        // paddingLeft: "15px",
        // paddingRight: "15px",
    
        // boxShadow:"0px 5px 15px rgba(0, 0, 0, 0.2)",
        outline: 'none',
        padding:"0px"
        }
      }}
      >
        <OrganizationForm closeOrgForm={closeOrgForm}/>
       </Modal> */}
       
    </Grid>
  )
}
