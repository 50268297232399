export default async function addskater(parentEmail ,first,last,dob,school,yearsSkating,shoeSize,brandToSkate, boardSize, parentName,parentPhone, address, state, city, zipcode) {

    // require('dotenv').config()
   
    const Realm = require("realm-web"); 
    const REALM_ID = process.env.REACT_APP_PUBLIC_REALM_APP_ID;
    const REALM_KEY = process.env.REACT_APP_PUBLIC_REALM_APP_KEY;
    // console.log("Id", REALM_ID)
    // console.log("key", REALM_KEY)
    const app = new Realm.App({id: REALM_ID});
    const {
        BSON: { ObjectId },
    } = Realm;

  // Authenticate the user
    try {
        const credentials = Realm.Credentials.apiKey(REALM_KEY)
        await app.logIn(credentials);
        // console.log(user)
    } catch (error) {
        console.log("Error in Logging", error) 
    }

    

  
    const mongo = app.currentUser.mongoClient("ehrcluster");
    const collection = mongo.db("ehrusers").collection("skaters");
    
    try {

    const results = await collection.insertOne({

        "parentEmail": parentEmail,
        "parentName": parentName,
        "skaterName": first,
        "skaterLast": last,
        "skateDob": dob,
        "school" : school,
        "yearSkating" : yearsSkating,
        "shoeSize" : shoeSize,
        "brandToSkate" : brandToSkate,
        "boardSize": boardSize,
        "parentPhone": parentPhone,
        "street_name": address,
        "state": state,
        "city": city,
        "zip_code": zipcode,
        
    })

    
    return results

    } catch (error) {

    console.log("Error from pulling Colletcion", error)  

    } 


  
}