

export default async function findvolunteer(email) {

    const Realm = require("realm-web"); 
    const REALM_ID = process.env.REACT_APP_PUBLIC_REALM_APP_ID;
    const REALM_KEY = process.env.REACT_APP_PUBLIC_REALM_APP_KEY;
    // console.log("Id", REALM_ID)
    // console.log("key", REALM_KEY)
    const app = new Realm.App({id: REALM_ID});
    const {
        BSON: { ObjectId },
    } = Realm;

  // Authenticate the user
    try {
        const credentials = Realm.Credentials.apiKey(REALM_KEY)
        await app.logIn(credentials);
        // console.log(user)
    } catch (error) {
        console.log("Error in Logging", error) 
    }
   
    // let userid = ""
    try {
       
        // console.log("Sub id", currentuser.username)
    } catch (error) {
        console.log(error)
    }

  
    const mongo = app.currentUser.mongoClient("ehrcluster");
    const collection = mongo.db("ehrusers").collection("volunteers");
    
    const volunteer = await collection.findOne({
         email: email
        });

    // console.log("Volunteer", volunteer);
    return volunteer
}
