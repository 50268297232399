import { Flex, Text } from '@aws-amplify/ui-react'
import { Grid } from '@mui/material'
import React from 'react'
import {motion} from 'framer-motion'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'

export default function NavBar() {

  const navigation = useNavigate()
  const [sidePanel, setsidePanel] = React.useState(false)
  const closeSidePanel = () => {
    setsidePanel(false)
  }
  const openSidePanel = () => {
    setsidePanel(true)
  }

  return (
    <>
    <Grid container

    position="relative"
    display="flex"
    width="100%"

    // maxHeight="188px"
    // height="188px"
    // backgroundColor="rgba(57, 137, 70, 0.6)"
    // backgroundColor="rgba(55, 138, 69, 0.6)"
    // backgroundColor="rgba(57, 98, 64, 0.6)"
    backgroundColor="rgba(32, 78, 41, 0.6)"
    >
      
    <motion.button
   
        
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => {
          navigation('/')
        }}
        >
          <Flex
          paddingLeft="10px">

      {/* <Image
    src="/ehr.png"
    alt="Home Logo"
    width="110px"
    height="70px"
    objectFit="cover"
    position="relative"
      /> */}


    </Flex>

    </motion.button>

    <Flex
    width="100%"
    // backgroundColor="red"
    height="70px"
    position="relative"
    paddingRight="30px"
    gap="15px"
    justifyContent="end"
    alignItems="center"
    textAlign="center"
    
  
    >
     
     {/* <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => {
          window.location.href = 'https://ehr90z.wixsite.com/my-site-2'
        }}
        >
      <Text
      color="white"
      fontSize="15px"
      >
        About
      </Text>
      </motion.button> */}
      

     <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => {
          navigation('/Home')
        }}
        >
      <Text
      color="white"
      fontSize="15px"
      >
        Home
      </Text>
      </motion.button>
      <motion.button
   
        
   whileHover={{ scale: 1.1 }}
   whileTap={{ scale: 0.9 }}
  
   >
      <Text
      color="white"
      fontSize="15px"
      onClick={openSidePanel}>
        Forms
      </Text>
      </motion.button>
    </Flex>

    </Grid>

    <Modal
      isOpen={sidePanel}
      onRequestClose={closeSidePanel}
      style={{
        overlay: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-end',
        height:"100vh",
        overflow: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 999,
    
        padding:"68px 0px 30px 0px"
      },

        content: {
        position: 'relative',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '0px solid #ccc',
    
        width:"300px",
        backgroundColor:"rgba(255,255,255,.9)",
        // backgroundColor:"rgba(32, 78, 41, 0.9)",
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        height:"250px",
        // paddingLeft: "15px",
        // paddingRight: "15px",
        // display: "flex",
        // justifyContent:"center",
    
        boxShadow:"0px 5px 15px rgba(0, 0, 0, 0.2)",
        outline: 'none',
        padding:"0px"
        }
      }}
      >

        <Text
        width="100%"
        textAlign="center"
        paddingTop="15px"
      
        >
       
        </Text>
        <Flex
        position="relative"
        height="auto"
        gap="10px"
        width="100%"
        direction="column"
        paddingTop="15px"
        paddingLeft="30px"
        paddingRight="30px"

        >

        <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      
        >

        <Text
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="40px"
        borderRadius="5px"
        border='1px solid #000000'
        textAlign="center"
        fontSize="14px"
        onClick={() =>{
          window.open("https://docs.google.com/forms/d/e/1FAIpQLSePbc24N3psTbMBRSbBRK0820IRNkop57MDtb04rP6xgNIaqA/viewform?usp=sf_link")
          
        }}
        >
          Brownsville Skate Clinic Sign Up
        </Text>
        </motion.button>
        <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        
      
        >

        <Text
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="40px"
        borderRadius="5px"
        border='1px solid #000000'
        textAlign="center"
        fontSize="14px"
        onClick={() =>{
          navigation('/VolunteerSignUp')
        }}
        >
          Volunteer Sign Up
        </Text>
        </motion.button>
        <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={openSidePanel}
        >

        <Text
         display="flex"
         justifyContent="center"
         alignItems="center"
        width="100%"
        height="40px"
        borderRadius="5px"
        border='1px solid #000000'
        fontSize="14px"
        onClick={() =>{
          navigation('/Organization')
        }}>
          Organization Sign Up
        </Text>
        </motion.button>

        <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      
        >

        <Text
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="40px"
        borderRadius="5px"
        border='1px solid #000000'
        textAlign="center"
        fontSize="14px"
        onClick={() =>{
          navigation('/SkaterSignUp')
        }}
        >
          First Saturday Sk8 Sign Up
        </Text>
        </motion.button>

        
        </Flex>
        
    </Modal>
    
    </>
  )
}
