import { Button, Text } from '@aws-amplify/ui-react'
import { Grid, TextField } from '@mui/material'
import React from 'react'
import addvolunteer from '../appuitls/addvolunteer'
// import signup from '../appuitls/awssignup'
// import Confirmation from './Confirmation'


export default function VolunteerPage(props) {
    const email = props.email
    // console.log("email", email)
    // const password = props.password
    const [first, setfirst] = React.useState("")
    const [last, setlast] = React.useState("")
    const [dob, setdob] = React.useState("")
    const [address, setaddress] = React.useState("")
    const [state, setstate] = React.useState("")
    const [city, setcity] = React.useState("")
    const [zipcode, setzipcode] = React.useState("")
    const [phone, setphone] = React.useState("")
    const [isdisabled, setisdisabled] = React.useState(true)
    const [ConfirmSignUp, setConfirmSignUp] = React.useState(false)
    const closeConfirmSignUp = () => {
      setConfirmSignUp(false)
    }

    React.useEffect(() => {
      if(email === "" || 
        // password === "" ||
        first === "" ||
        last === "" ||
        dob === "" ||
        address === "" ||
        phone === "" ||
        state === "" ||
        city === "" ||
        zipcode === ""
        ){
          // alert("All fields required!")
          setisdisabled(true)
          return
        }
      setisdisabled(false)
      
    }, [email ,first,last,dob,address,phone, state, city, zipcode])

    const SignUp = async () => {

      await addvolunteer(email, first, last, dob, address, phone, state, city, zipcode);
      console.log("Successfully Signed Up!")
      alert("You've successfully Signed Up as a Volunteer!")
      window.location.href = 'https://ehr90z.wixsite.com/my-site-2'

  
      setConfirmSignUp(true)
    }

    
    
  return (
    <Grid container
    display="block"
    // justifyContent="center"
    // alignItems="center"
    position="relative"
    height="100%"
    width="100%"
    // backgroundColor="red"
    // overflow="unset"
    // overflow="hidden"
    // direction="column"
    >

{/* <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/ehrbggifsignup.gif"
    // srcSet='/home.svg 300w'
    alt="Home Logo"
    width="100%"
    height="100vh"
    objectFit="cover"
    // objectPosition="top"
    position="fixed"
    // overflow="scroll"
    /> */}

    <Grid container
    display="flex"
    justifyContent="center"
    alignItems="center"
    position="relative"
    height="100%"
    // padding="50px 40px 50px 40px"
    // borderRadius="15px"
    // backgroundColor="rgba(255,255,255,.8)" 
    width="100%"
    >



      <Grid container item
            // maxWidth="650px"
            width="100%"
            // backgroundColor="pink"
        // height=""
        // maxHeight="200vh"
        // display={(props.showsignup ? "flex" : "none")}
            flexWrap="nowrap"
        // overflow="scroll"
        // gap="unset"
        // alignItems="center"
        // justifyItems="center"
            justifyContent="center"
        // paddingLeft="20px"
        // paddingRight="20px"
            paddingTop="45px"
            paddingBottom="50px"
            position="relative"
            borderRadius="15px"
            boxShadow="0px 5px 15px rgba(0, 0, 0, 0.10000000149011612)"
            // borderRadius="20px"
            backgroundColor="rgba(255,255,255, .6)"
            // backgroundColor="rgba(32, 78, 41, 0.8)"
        >






    
        <Grid item container
    // maxWidth="524px"
    // minWidth="400px"
        height="auto"
        display="flex"
        gap="unset"
        direction="column"
        alignItems="unset"
        justifyContent="unset"
        position="relative"
    // top="10px"
    // marginLeft="20px"
    // marginRight="20px"
    // paddingLeft="100px"
    // paddingLeft="20px"
    // paddingRight="20px"
    // left="48px"
    // paddingTop="20px"
        paddingLeft="50px"
        paddingRight="50px"
    // padding="0px 0px 0px 0px"
    // backgroundColor="grey"
   
    >
   

    {/* Sign Up Text  */}
      <Grid container
    position="relative"
    // minWidth="100px"
    height="auto"
    display="flex"
    justifyContent="center"
    textAlign="center"
    wrap="nowrap"
// wrap="nowrap"

// paddingBottom="20px"
// padding
// backgroundColor="brown"
>

    
      <Text
        fontFamily="Nunito"
        fontSize="40px"
        // fontWeight="700"
        color="green"
        lineHeight="50px"
        textAlign="centers"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="-0.85px"
        
        // backgroundColor="pink"
        // width="169px"
        // height="47px"
        gap="unset"
        alignItems="unset"
        position="relative"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        
       
      >
        Volunteer Information
      </Text>
   

      </Grid>
    {/* Sign Up Text  */}


 {/* Enter Username, Email, Sign Up Screen */}
      <Grid container
        as="form"
        gap="20px"
        direction="column"
        width="unset"
        // height="100%"
        display="flex"
        // justifyContent="flex-start"
        // alignItems="flex-start"
        position="relative"
        // top="117px"
        left="0px"
        // padding="0px 0px 0px 0px"
        paddingTop="15px"
        // backgroundColor="yellow"
        >

{/* Enter Username, Email, Password, Forgot password. Reset New Group */}
     

      <TextField
          label="First Name ..."
          value={first}
    onChange={(e) => {
      let { value } = e.target;
      setfirst(value);
    }}
 
           
          >  
      </TextField>

      <TextField
    
            // // className="Sign-Up"
            label="Last Name ..."
            value={last}
            onChange={(e) => {
            let { value } = e.target;
            setlast(value);
             }}
             >
          
            
            
         
      </TextField>

      <TextField
            label="DOB ..."
            value={dob}
            onChange={(e) => {
            let { value } = e.target;
            setdob(value);
             }}
             >
          
                {/* <Text
                  fontFamily="Nunito"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(107,107,107,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Enter Email ..."
                  {...getOverrideProps(overrides, "Placeholder2144")}
                ></Text> */}
            
         
      </TextField>

      <TextField
             label="Street Name ..."
             value={address}
             multiline
            onChange={(e) => {
            let { value } = e.target;
            setaddress(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="State ..."
             value={state}
         
            onChange={(e) => {
            let { value } = e.target;
            setstate(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="City ..."
             value={city}
          
            onChange={(e) => {
            let { value } = e.target;
            setcity(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="Zip Code ..."
             value={zipcode}
             

            onChange={(e) => {
            let { value } = e.target;
            setzipcode(value);
             }}
             >
            
         
      </TextField>

      <TextField
             label="Phone Number ..."
             value={phone}
            onChange={(e) => {
            let { value } = e.target;
            setphone(value);
             }}
             >
          
             
            
         
      </TextField>

{/* Sign Up Button */}
      <Button
        variation="primary"
        width="unset"
        height="48px"
        display="flex"
        
        gap="unset"
        alignItems="center"
        justifyContent="center"
        paddingLeft="10px"
        paddingRight="10px"
        // wrap="wrap"
       
        // top="12px"
        position="relative"
        borderRadius="15px"
        // padding="10px 10px 10px 10px"
        isDisabled={isdisabled}
        onClick={() => {
          SignUp()
        }}
        backgroundColor="green"
        // opacity="0.5"
       
      >

        
        <Text
            fontFamily="Nunito"
            fontSize="19px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            // children="Sign up"
          
          >
            Sign Up
          </Text>

        
      </Button>
{/* Sign Up Button */}

        </Grid>
{/* Enter Username, Email, Sign Up Screen */}

        </Grid>


      
      </Grid>

      {/* <Modal
      isOpen={ConfirmSignUp}
      onRequestClose={closeConfirmSignUp}
      style={{
        overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        zIndex: 999,
        // paddingTop:"20px",
        // paddingBottom:"20px",
        padding:"20px 50px 20px 50px"
      },

        content: {
        position: 'relative',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        border: '0px solid #ccc',
      //   background: 'blue',
      //   width: "302px",
      //   overflow: 'auto',
        width:"100%",
        backgroundColor:"rgba(255,255,255,.9)",
        WebkitOverflowScrolling: 'touch',
        borderRadius: '15px',
        height:"auto",
        maxWidth:"600px",
        // paddingTop:"20px",
        // paddingBottom:"20px",
        boxShadow:"0px 5px 15px rgba(0, 0, 0, 0.2)",
        outline: 'none',
        padding:"0px"
        }
      }}
      >

      <Confirmation
      email={email}
      />

      </Modal>
       */}
    </Grid>

    </Grid>
  )
}
